<template>
  <div class="withdraw">
    <div class="withdraw-box">
      <img class="withdraw-box-img" src="@/assets/withdrawalcon.png" alt="">
      <div class="withdraw-box-text">该合同已被{{initiatorName}}撤回，无法查看详情</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    initiatorName() {
      console.log(this.$route.query);
      return this.$route.query.initiatorName
    }
  }
}
</script>

<style lang='less' scoped>
.withdraw {
  width: 100%;
  height: 100%;
  background: #EEF3FC;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &-box {
    width: 256px;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img {
      width: 200px;
      height: 150px;
    }
    &-text {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #30586C;
      opacity: 1;
      margin-top: 8px;
    }
  }
}
</style>